<template>
  <transition name="fade" mode="out-in">
    <base-table v-if="list.length" :list="list" :columns="tableHead" class="standart-text">
      <!--        :sort="sort"-->
      <!--        @sort-change="onSortChange"-->
      <base-table-row v-for="(item, i) in list" :key="i" :item="item" :columns="tableHead">
        <template #name>
          {{ item.name }}
        </template>
        <template #date>
          <!--          {{ format(item.createTime, 'dd.MM.yyyy HH:mm') }}-->
          <!--          {{ item.createTime }}-->
          {{ created(item) }}
          <!--          {{ created(item).split(' ')[1] }}-->
          <!--          {{ // format(item.createTime, 'dd.MM.yyyy HH:mm:ss') }}-->
          <!--          {{ $d(item.createTime, 'long') }}-->
        </template>
        <template #size>
          {{ (item.size / Math.pow(1024, 3)).toFixed(2) }}
        </template>
        <template #status>
          <main-label :color="currentColor(item)" theme="plain">
            {{ statusName(item) }}
          </main-label>
        </template>
        <template #recover>
          <plain-button color="dim" class="backup-banner__btn" @click="$emit('recover', item)">
            {{ $t('recover') }}
          </plain-button>
        </template>
        <template #delete>
          <plain-button color="del" class="backup-banner__btn" @click="$emit('remove', item)">
            {{ $t('remove') }}
          </plain-button>
        </template>
      </base-table-row>
    </base-table>
    <base-loader v-else />
  </transition>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable.vue';
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
import MainLabel from '@/components/Labels/MainLabel';
import { format } from 'date-fns';
export default {
  name: 'BackupBanner',
  components: { BaseTable, BaseTableRow, MainLabel },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'name',
          label: this.$t('name'),
          style: {
            width: '33%',
            minWidth: '160px',
          },
        },
        {
          key: 'date',
          label: this.$t('date'),
          style: {
            width: '33%',
            minWidth: '135px',
          },
        },
        {
          key: 'size',
          label: this.$t('size'),
          style: {
            width: '33%',
            minWidth: '132px',
          },
        },
        {
          key: 'status',
          label: this.$t('status'),
          style: {
            width: '60px',
          },
        },
        {
          key: 'recover',
          // label: this.$t('name'),
          style: {
            // width: '33%',
            minWidth: '30px',
          },
        },
        {
          key: 'delete',
          // label: this.$t('name'),
          style: {
            // width: '33%',
            minWidth: '30px',
          },
        },
      ],
    };
  },
  mounted() {},
  methods: {
    statusName(item) {
      return item._state.key === 'finished'
        ? 'Создан'
        : item._state.key === 'pending'
        ? 'Ожидает выполенния'
        : 'Ошибка';
    },
    created(i) {
      return format(i.createTime, 'dd.MM.yyyy HH:mm:ss');
    },
    currentColor(item) {
      return item._state.key === 'finished'
        ? 'success'
        : item._state.key === 'pending'
        ? 'warning'
        : 'error';
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "name": "Имя",
    "date": "Дата создания",
    "size": "Размер, ГБ",
    "recover": "Восстановить",
    "status": "Статус",
    "remove": "Удалить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.backup-banner {
  &__row {
    +breakpoint(sm-and-up) {
      flexy(space-between, center);
    }

    & + & {
      margin-top: 1.5rem;
    }
  }

  &__text {
    word-break: break-word;
  }

  &__name {
    word-break: break-word;
  }

  &__actions {
    flexy(flex-start, center);
    margin-top: 1rem;

    +breakpoint(sm-and-up) {
      justify-content: flex-end;
      margin-top: 0;
    }
  }

  &__btn {
    & + & {
      margin-left: 1.5rem;
    }
  }
}
</style>
